import React from "react";
import Back from "../common/Back";
import Heading from "../common/Heading";
import img from "../images/about.jpg";
import "./about.css";
import { useState, useRef, useEffect } from "react";

const About = () => {
  const inputRef = useRef();

  useEffect(() => {
    const divElement = inputRef.current;
    console.log(divElement); // logs <div>I'm an element</div>
  }, []);

  return (
    <>
      <section className="about">
        <Back name="About Us" title="About Us - ?" cover={img} />
        <div className="container flex mtop">
          <div className="left row">
            <Heading
              title="Our Agency Story"
              subtitle="Check out our company story and work process"
            />
            <div>{localStorage.getItem("Inputvalue")}</div> <br />
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip.
            </p>
            <button className="btn2">More About Us</button>
          </div>
          <div className="right row">
            <img src="./immio.jpg" alt="" />
          </div>
        </div>
      </section>
    </>
  );
};

export default About;
