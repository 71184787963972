import React from "react";
import Heading from "../../common/Heading";
import "./hero.css";
import { useRef } from "react";
import { Link } from "react-router-dom";

const Hero = () => {
  const data = useRef();

  const handleClick = () => {
    console.log(data.current.value, "initial value");
    localStorage.setItem("Inputvalue", data.current.value);
  };

  return (
    <>
      <section className="hero">
        <div className="container">
          <Heading title="" subtitle="" />
          <form className="flex">
            <div className="box">
              <input
                ref={data}
                minLength="3"
                id="item"
                type="text"
                placeholder="Find yourself"
              />
            </div>
            <Link to={"/About"}>
              <button
                onClick={() => {
                  handleClick();
                }}
                className="btn1"
                path="/about"
              >
                <i className="fa fa-search"></i>
              </button>
            </Link>
          </form>
        </div>
        {/* <h2>{localStorage.getItem("Inputvalue")}</h2> */}
      </section>
    </>
  );
};

export default Hero;
